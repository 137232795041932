import React, { useState } from 'react';
import { useCanvas, updateCurveSettings } from './hooks/useCanvas';
import useMouse from '@react-hook/mouse-position'
import Container from 'react-bootstrap/Container'


/* BOOTSTRAP */
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'


// Sidebar
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'


// Icons
import { PenFill, HandIndexFill, PaintBucket, Download, Easel2Fill } from 'react-bootstrap-icons';



import './App.css';

function App() {

  
  // Canvas handler
  const [editMode, setEditMode, canvasMousePointer, setCanvasMousePointer, currentCurve, setCurrentCurve, updateCurrentCurveToLibrary, addPointToCurrentCurve, resetCurrentCurve, addPointToLibrary, curveLibrary, setCurveLibrary, canvasRef, canvasWidth, canvasHeight, clearCanvas] = useCanvas();
  
  // Mouse tracker 
  const mouse = useMouse(canvasRef, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  const handleModeChange = (val) => { 
    // Draw mode
    if (editMode == 1) {
      // Save the new curve
      updateCurrentCurveToLibrary();
    }

    setEditMode(val);
  };

  const handleCanvasClick=(event)=>{
    // on each click get current mouse location 
    const currentCoord = { x: mouse.x, y: mouse.y };

    // If in Draw mode
    if (editMode == 1) {
      var pointIndex = addPointToLibrary(currentCoord);

      addPointToCurrentCurve(pointIndex);
    }

  };

  const handleCanvasDoubleClick=(event)=>{
    // Draw mode
    if (editMode == 1) {
      // Save the new curve
      updateCurrentCurveToLibrary();
    }
  };


  const handleClearCanvas=(event)=>{
    clearCanvas();
  };

  const handleStrokeTypeChange = (event) => { 
    setCurrentCurve(
      {...currentCurve, // Copy the original object
        'lineDash': event.target.value
      }
    );
  };

  const handleDownloadPNG = (event) => {
    var link = document.createElement('a');
    link.download = 'graph-sketcher.png';
    link.href = canvasRef.current.toDataURL()
    link.click(canvasRef.current);
  };

  const handleCurveTensionChange = (event) => {
    setCurrentCurve({...currentCurve, 'tension': event.target.value/100});
    setTension(event.target.value);
  };
  const handleCurveForceChange = (event) => {
    setCurrentCurve({...currentCurve, 'force': event.target.value/100});
    setForce(event.target.value);
  };

  const handleCurveColorChange = (event) => {
    console.log(event.target.value)
    setCurrentCurve({...currentCurve, 'lineColor': event.target.value});
  };


  const [tension, setTension] = useState(0)
  const [force, setForce] = useState(0)
  const [sidebarTabKey, setSidebarTabKey] = useState('curve');


  return (
      <Container fluid className="App">
        <Row className="App-header">
          <Col xs={12} className="shadow">
            <h3>Graph-Sketcher</h3>
          </Col>
        </Row> 
        <Row className="App-main justify-content-md-center">
          <Col xs={9}>
            <Card>
              <Card.Body className="Toolbar">
              <Container>
                <Row>
                  <Col xs={3}>
                    <ToggleButtonGroup name="editMode" type="radio" value={editMode} onChange={handleModeChange}>
                      <ToggleButton id="editMode-draw" value={1}>
                        <PenFill />Draw
                      </ToggleButton>
                      <ToggleButton id="editMode-modify" value={2}>
                        <HandIndexFill />Modify
                      </ToggleButton>

                    </ToggleButtonGroup>
                  </Col>
                  <Col md={{ span: 4, offset: 5 }}>
                    <ButtonGroup aria-label="Download">
                      <Button variant="secondary" onClick={handleDownloadPNG}><Download />PNG</Button>
                      {/* <Button variant="secondary">SVG</Button> */}
                    </ButtonGroup>
                    <Button onClick={handleClearCanvas}><Easel2Fill /> CLEAR </Button>
                  </Col>
                </Row>
              </Container>
              </Card.Body>
              <hr className="Toolbar-Divider" />
              <Card.Body className="Canvas-container">
                <canvas 
                  className="Canvas"
                  ref={canvasRef}
                  width={canvasWidth}
                  height={canvasHeight}
                  onClick={handleCanvasClick} 
                  onDoubleClick={handleCanvasDoubleClick} 
                  style={{cursor: canvasMousePointer}}
                  />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card >

              <Tabs
                id="sidebar-tab"
                activeKey={sidebarTabKey}
                onSelect={(k) => setSidebarTabKey(k)}
                className="mb-3"
              >
                <Tab eventKey="curve" title="Curve">
                  <Card.Body>
                    <Card.Text>
                      <Form.Label>Stroke style</Form.Label>
                      <Form.Select aria-label="Select line stlye" value={currentCurve.lineDash} onChange={handleStrokeTypeChange}>
                        <option value="solid">Solid</option>
                        <option value="dashed">Dashed</option>
                        <option value="dotted">Dotted</option>
                      </Form.Select>

                      <Form.Label>Color</Form.Label>
                      <Form.Control
                        type="color"
                        value="{currentCurve.color}"
                        onChange={handleCurveColorChange} 
                        title="Choose the Curve color"
                      />
                      
                      <Form.Label>Tension: {tension}</Form.Label>
                      <Form.Range onChange={handleCurveTensionChange}/>

                      <Form.Label>Force: {force}</Form.Label>
                      <Form.Range onChange={handleCurveForceChange}/>
                    </Card.Text>
                  </Card.Body>
                </Tab>
                <Tab eventKey="axes" title="Axes">
                  <Card.Body>
                    <Card.Text>2222</Card.Text>
                  </Card.Body>
                </Tab>
                <Tab eventKey="canvas" title="Canvas">
                  <Card.Body>
                    <Card.Text>333333</Card.Text>
                  </Card.Body>
                </Tab>
              </Tabs>
            </Card>
          </Col>
        </Row> 
      </Container>
  );
};

export default App;
