import React, { useState, useEffect, useRef } from 'react';
import useMouse from '@react-hook/mouse-position'
import Row from 'react-bootstrap/Row'

const defaultCurveSettings = {
        curveId: null,
        lineBlur: 0,
        lineWidth: 2,
        lineDash: 'solid',
        lineColor: '#000000',
        force:0.64, // 0 is a straight line...
        tension:0.55,
        showPoints: true,
        pointSize: 5, // Pixels
        coordinates: []
    }

const pointCloseTolerance = 20;

// Scaling Constants for Canvas
const SCALE = 0.1;
const OFFSET = 80;
const control_points = 100;
export const canvasWidth = 650 // window.innerWidth * .50;
export const canvasHeight = 450 // window.innerHeight * .8;


function gradient(a, b) {
    return (b.y-a.y)/(b.x-a.x);
}

function uidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

const drawAxes = (ctx) => {
    const wrapper = 40;
    const axisWidth = 1;
    const axisXmarks = 10;
    const axisYmarks = 10;
    const axisColor = 'black';

    // X Axis
    ctx.beginPath();
    ctx.setLineDash([0,0]); 
    ctx.lineWidth = axisWidth;
    ctx.strokeStyle = axisColor;
    ctx.moveTo(wrapper, canvasHeight-wrapper);
    ctx.lineTo(canvasWidth-wrapper, canvasHeight-wrapper);
    ctx.stroke();

    // Draw X axis intermediate marks
    for (var i = 0; i < axisXmarks+1; i++) {
        const markGap = (canvasWidth-2*wrapper)/axisXmarks

        ctx.beginPath();
        ctx.lineWidth = axisWidth;
        ctx.moveTo(wrapper+i*markGap, canvasHeight-wrapper-4);
        ctx.lineTo(wrapper+i*markGap, canvasHeight-wrapper+4);
        ctx.stroke();
    }

    // Y Axis
    ctx.beginPath();
    ctx.lineWidth = axisWidth;
    ctx.moveTo(wrapper, canvasHeight-wrapper+(axisWidth/2)); // Make the Y Axis start at the bottom of the X Axis 
    ctx.lineTo(wrapper, wrapper);
    ctx.stroke();

    // Draw Y axis intermediate marks
    for (var i = 0; i < axisYmarks+1; i++) {
        const markGap = (canvasHeight-2*wrapper)/axisYmarks

        ctx.beginPath();
        ctx.lineWidth = axisWidth;
        ctx.moveTo(wrapper-4, canvasHeight-wrapper-i*markGap);
        ctx.lineTo(wrapper+4, canvasHeight-wrapper-i*markGap);
        ctx.stroke();
    }
};

export function useCanvas(){
    const canvasRef = useRef(null);
    const [editMode, setEditMode] = useState(1);
    const [canvasMousePointer, setCanvasMousePointer] = useState('default');

    const [currentCurve, setCurrentCurve] = useState(defaultCurveSettings);
    const [currentPoint, setCurrentPoint] = useState();
    const [pointLibrary, setPointLibrary] = useState({});
    const [curveLibrary, setCurveLibrary] = useState({});
    

    // Mouse tracker
    const mouse = useMouse(canvasRef, {
        enterDelay: 100,
        leaveDelay: 100,
    });

    const drawCurves = (ctx) => {
        for (var key in curveLibrary) {
            if (curveLibrary.hasOwnProperty(key)) {   
                drawCurve(ctx, curveLibrary[key]);
            }
        }
    };

    const drawCurve = (ctx, curve, extraPoint = null) => { 
        var f = curve.force; // 0 is a straight line...
        var t = curve.tension;

        if (curve.coordinates.length > 0) {
            // Prepare stroke style
            ctx.lineWidth = curve.lineWidth;
            ctx.strokeStyle = curve.lineColor;
            ctx.fillStyle = curve.lineColor;
            ctx.shadowBlur = curve.lineBlur;
            ctx.shadowColor = curve.lineColor;

            // See: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
            switch(curve.lineDash) {
              case 'solid':
                    ctx.setLineDash([0,0]); 
                    break;
                case 'dotted':
                    ctx.setLineDash([3,3]); 
                    break;
                default:
                    ctx.setLineDash([8,10]); 
            }
            
            // Create curve path
            var curvePath = new Path2D();
            var curP = pointLibrary[curve.coordinates[0]];

            // Draw first point
            if(curve.showPoints){
                ctx.fillRect(curP.x-curve.pointSize/2, curP.y-curve.pointSize/2, curve.pointSize, curve.pointSize);
            }
            
            curvePath.moveTo(curP.x, curP.y); // Starting Coordinates


            var m = 0;
            var dx1 = 0;
            var dy1 = 0;
            var dx2, dy2, nexP;

            var preP = pointLibrary[curve.coordinates[0]];

            for (var i = 1; i < curve.coordinates.length; i++) {
                curP = pointLibrary[curve.coordinates[i]];
                
                if(extraPoint != null && i == curve.coordinates.length-1){
                    nexP = extraPoint;        
                }
                else{
                    nexP = pointLibrary[curve.coordinates[i+1]];
                }
                
                
                if(curve.showPoints){
                    ctx.fillRect(curP.x-curve.pointSize/2, curP.y-curve.pointSize/2, curve.pointSize, curve.pointSize);
                }

                if (nexP) {
                    m = gradient(preP, nexP);
                    dx2 = (nexP.x - curP.x) * -f;
                    dy2 = dx2 * m * t;
                } else {
                    dx2 = 0;
                    dy2 = 0;
                }
                curvePath.bezierCurveTo(preP.x - dx1, preP.y - dy1, curP.x + dx2, curP.y + dy2, curP.x, curP.y);
                dx1 = dx2;
                dy1 = dy2;
                preP = curP;
            }

            if(extraPoint != null){
                curP = extraPoint;
                ctx.fillRect(curP.x-2, curP.y-2,5,5); // Small square

                nexP = curP;
                if (nexP) {
                    m = gradient(preP, nexP);
                    dx2 = (nexP.x - curP.x) * -f;
                    dy2 = dx2 * m * t;
                } else {
                    dx2 = 0;
                    dy2 = 0;
                }
                curvePath.bezierCurveTo(preP.x - dx1, preP.y - dy1, curP.x + dx2, curP.y + dy2, curP.x, curP.y);    
            }
            ctx.stroke(curvePath);
        }
    };

    // The UI needs these functions
    const addPointToLibrary = (point, skipLookup = false) => {
        // Check if the point exists
        if (!skipLookup) {
            for (var key in pointLibrary) {
                if (pointLibrary.hasOwnProperty(key)) {   
                    var dist = (point.x-pointLibrary[key].x)**2+(point.y-pointLibrary[key].y)**2
                    if (dist < pointCloseTolerance){
                        return key;
                    }
                }
            }
        }

        // Add the point to the library if the point doesn't exists
        var uniqueId = uidGenerator();
        point.pointId = uniqueId;

        setPointLibrary({...pointLibrary, [uniqueId]:point})
        return uniqueId;
    };

    const addPointToCurrentCurve = (pointIndex) => { 
        // Only add the point if this is not the same as the last one (avoiding problems with double clicks!)
        if (currentCurve.coordinates.slice(-1)[0] != pointIndex) {
            setCurrentCurve(
                {...currentCurve, // Copy the original object
                  'coordinates': [...currentCurve.coordinates, pointIndex]
                }
              );
        }
    };

    const updateCurrentCurveToLibrary = () =>{
        // Only do something if the current curve has at least one point
        if (currentCurve.coordinates.length > 0) {
            // Check if the curve is in the library
            if (currentCurve.curveId == null) {
                var uniqueId = uidGenerator();
                setCurveLibrary({...curveLibrary, [uniqueId]: {...currentCurve, curveId: uniqueId}});
            }
            else{
                setCurveLibrary({...curveLibrary, [currentCurve.curveId]: currentCurve});
            }
            resetCurrentCurve();
        }
    };

    const resetCurrentCurve = () => { setCurrentCurve(defaultCurveSettings) };

    const clearCanvas = () => { 
        setCurveLibrary([]);
        setPointLibrary([]);
        resetCurrentCurve();
    };


    useEffect(()=>{
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext('2d');
        const currentCoord = { x: mouse.x, y: mouse.y };

        // clear the canvas area before rendering the coordinates held in state
        ctx.clearRect( 0,0, canvasWidth, canvasHeight );

        // Draw the canvas and its content
        drawAxes(ctx)
        drawCurves(ctx)

        ////////////
        // Control interactions with the Canvas
        ////////////

        // Reset the mouse pointer
        setCanvasMousePointer('default'); // See https://developer.mozilla.org/es/docs/Web/CSS/cursor


        //
        // Drawing Mode
        //
        if (editMode == 1) {
            if (mouse.isOver) {
                setCanvasMousePointer('copy');

                if (currentCurve.coordinates.length > 0) {
                    drawCurve(ctx, currentCurve, currentCoord);
                }     
            }
            else {
                if (currentCurve.coordinates.length > 0) {
                    drawCurve(ctx, currentCurve);
                } 
            }

        }

        //
        // Modify Mode
        //
        if (editMode == 2) {
            if (mouse.isOver) {
                for (var key in pointLibrary) {
                    if (pointLibrary.hasOwnProperty(key)) {   
                        var dist = (currentCoord.x-pointLibrary[key].x)**2+(currentCoord.y-pointLibrary[key].y)**2
                        if (dist < pointCloseTolerance*2){
                            // Switch the mouse pointer to indicate the point is draggable!
                            setCanvasMousePointer('move');
                            ctx.fillStyle = "blue";
                            ctx.fillRect(pointLibrary[key].x-6, pointLibrary[key].y-6,15,15); // Small square


                            if (mouse.isDown) {
                                setPointLibrary({...pointLibrary, [key]: {...pointLibrary[key], x:currentCoord.x, y:currentCoord.y}});
                            }
                        }
                    }
                } 

                /*
                for (var key in curveLibrary) {
                    if (curveLibrary.hasOwnProperty(key)) {   
                        if(ctx.isPointInStroke(curveLibrary[key], currentCoord.x, currentCoord.y)){
                            setCurveLibrary({...curveLibrary, [key]: {...curveLibrary, lineBlur: 15}});
                        }
                        else if(curveLibrary[key].lineBlur > 0){
                            setCurveLibrary({...curveLibrary, [key]: {...curveLibrary, lineBlur: 0}});
                        }
                    }
                } */
            }

        }

        //
        // Fill Mode
        //
        if (editMode == 3) {
            if (mouse.isOver) {
                ctx.fillRect(currentCoord.x-2, currentCoord.y-2,20,20);
            }
        }  
    });

    // ctx.quadraticCurveTo

    return [
        editMode, setEditMode, canvasMousePointer, setCanvasMousePointer,
        currentCurve, setCurrentCurve, updateCurrentCurveToLibrary, addPointToCurrentCurve, resetCurrentCurve,
        addPointToLibrary, curveLibrary, setCurveLibrary,
        canvasRef, canvasWidth, canvasHeight, clearCanvas 
    ];
}

